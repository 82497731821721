.App {
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.Menu {
    height: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 16px;
}

.MenuIcon {
    width: 32px;
    height: 32px;
}

.Split > div:hover {
    cursor: w-resize;
}

.WebViewContainer {
    flex: 1;
    display: flex;
}

.WebViewContainer > div {
    display: flex;
    flex: 1;
    background-color: #353535;
}

.WebViewContainer iframe {
    border: none;
}
